// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/titillium-web-v15-latin-200.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/titillium-web-v15-latin-200.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/titillium-web-v15-latin-regular.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/titillium-web-v15-latin-regular.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../fonts/titillium-web-v15-latin-700.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../fonts/titillium-web-v15-latin-700.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "/* @import url('https://fonts.googleapis.com/css?family=Titillium+Web:200,400,700'); */\n\n/* titillium-web-200 - latin */\n@font-face {\n  font-family: 'Titillium Web';\n  font-style: normal;\n  font-weight: 200;\n  src: local(''),\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'), \n       url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */\n}\n/* titillium-web-regular - latin */\n@font-face {\n  font-family: 'Titillium Web';\n  font-style: normal;\n  font-weight: 400;\n  src: local(''),\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff2'), \n       url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */\n}\n/* titillium-web-700 - latin */\n@font-face {\n  font-family: 'Titillium Web';\n  font-style: normal;\n  font-weight: 700;\n  src: local(''),\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('woff2'), \n       url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */\n}\n\n\nbody {\n  font-family: 'Titillium Web', sans-serif;\n  font-size: 16px;\n}\n\nh1, h2, h3, h4, h5, h6 {\n  font-family: 'Titillium Web', sans-serif;\n}\n\nh1 {\n  font-size: 2.5rem;\n}\nh2 {\n  font-size: 2rem;\n}\nh3 {\n  font-size: 1.75rem;\n}\nh4 {\n  font-size: 1.5rem;\n}\nh5 {\n  font-size: 1.25rem;\n}\nh6 {\n  font-size: 1rem;\n}\n\n.light {\n  font-weight: 200;\n}\n\n", ""]);
// Exports
module.exports = exports;
